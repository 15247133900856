.swiper {
    width: 100%;
    height: 350px !important;
    padding-bottom: 15px;

    @media (max-width: 900px) {
        height: auto !important;
        padding-bottom: 45px;
    }
}

.swiper-pagination {
    display: none !important;

    @media (max-width: 900px) {
        display: block !important;
        bottom: 10px !important;
    }
}

.swiper-pagination-bullet-active {
    background: #cdab71;
}