.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /*height: 100vh;*/
}

* {
  box-sizing: border-box;
}

.body-no-scroll {
  overflow: hidden;
}