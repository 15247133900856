$mobile: 319.99px;
$tablet: 599.99px;
$desktopS: 899.99px;
$desktopSNew: 1023.99px;
$desktop: 1199.99px;
$desktop1440: 1439.99px;
$desktop1600: 1599.99px;
$mega: 1919.99px;

// настройки элементов навигации
.image-gallery-icon {
  outline: none;
  appearance: none;

  padding: 16px 25px;
  color: #cbad73;

  background-color: transparent;

  border: 0;
  z-index: 4;

  cursor: pointer;
}

.image-gallery-left-nav {
  order: 1;
}
.image-gallery-right-nav {
  order: 3;
}

// настройка контейнера с основной фотографией
.image-gallery-swipe {
  order: 2;
  width: 100%;
}

.image-gallery {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.image-gallery-content {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 18px;
}

.image-gallery-image {
  width: 100%;
  object-fit: contain;
  // уменьшаем контейнер фотографии на размер элементов переключения слайдов
  //max-width: calc(860px - 140px);
  max-height: calc(100vh - 108px - 152px - 18px - 127px); // вычитаем все элементы внутри и снаружи модалки
  min-height: 280px;

  @media (max-width: $desktop1600) {
    max-height: calc(100vh - 108px - 102px - 18px - 127px);
  }

  @media (max-width: $desktop1440) {
    max-height: calc(100vh - 72px - 102px - 18px - 127px);
  }

  @media (max-width: $desktopSNew) {
    max-width: 100%;
  }
}

.image-gallery-slide-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.image-gallery-center {
    position: relative;
  }
}

// настройка нижней галереи слайдов
.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;

    padding: 0 70px;

    @media (max-width: $desktopSNew) {
      padding: 0 16px;
    }
  }

  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }

  &.image-gallery-thumbnails-left,
  &.image-gallery-thumbnails-right {
    display: inline-block;
    vertical-align: top;
    width: 100px;
  }
}

// делаем контейнер скорльным и скрываем скролл
.image-gallery-thumbnails {
  overflow: hidden;
  white-space: nowrap;

  .image-gallery-thumbnails-container {
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .image-gallery-thumbnail {
      flex: 0 0 auto;
      margin-right: 16px;

      @media (max-width: $desktopSNew) {
        margin-right: 14px;
      }


      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 60px;

        @media (max-width: $desktopSNew) {
          margin-right: 40px;
        }
      }
    }
  }
}

.image-gallery-thumbnail {
  padding: 0;

  width: auto;
  height: auto;

  object-fit: contain;

  border: 3px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;

  overflow: hidden;
  background: transparent;
  cursor: pointer;

  @media (max-width: $desktopSNew) {
    border: 1.5px solid transparent;
    border-radius: 2px;
  }

  // избавляемся от эффекта залипания рамки на мобильных устройствах
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      outline: none;
      border: 3px solid #b09767;

      @media (max-width: $desktopSNew) {
        outline: none;
        border: 1.5px solid #b09767;
      }
    }
  }

  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;

    border-radius: 2px;
    border: 1px solid #ffffff;
    box-sizing: border-box;

  }

  .image-gallery-thumbnail-image {
    width: 120px;
    height: 120px;

    vertical-align: middle;
    object-fit: cover;
    border-radius: 3px;
    box-sizing: border-box;


    @media (max-width: $desktopSNew) {
      width: 80px;
      height: 80px;
    }
  }

  &.active {
    outline: none;
    border: 3px solid #cbad73;

    @media (max-width: $desktopSNew) {
      border: 1.5px solid #cbad73;
    }
  }
}

// new styles
.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8F8F8;
  width: 100%;
  height: 100%;
  max-height: 1080px;
  margin: auto;
}

